
/**
 * User Profile view.
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { mapGetters, mapActions } from "vuex";
import Multiselect from "vue-multiselect";

import CustomPagination from "@/components/dashboard/CustomPagination.vue";
import TableHeader from "@/components/tableHeader.vue";
import { ActionMessage } from "@/model/Messages/statusMessage";
import { TranslateResult } from "vue-i18n";
import { LogsPagination } from "@/stores/modules/SystemLogs";

@Component({
  name: "UserLogs",
  components: {
    CustomPagination,
    Multiselect,
    TableHeader
  },
  computed: {
    ...mapGetters("SystemLogs", {
      GET_USERS_LOGS: "GetUserLogs",
      GET_USER_LOGS_PAGINATION: "getUserLogPagination",

      GET_FORMATED_DATA: "getFormattedEntryData"
    })
  },
  methods: {
    ...mapActions("SystemLogs", {
      GET_USERS_LOGS_FROM_BACKEND: "GET_ALL_USERS_LOGS"
    })
  }
})
export default class UserLogs extends Vue {
  /** Map Getter from the SystemLogs module */
  private readonly GET_USERS_LOGS!: Array<object>;
  readonly GET_USER_LOGS_PAGINATION!: LogsPagination;
  private readonly GET_FORMATED_DATA!: Array<string>;
  /** Map Actions from the SystemLogs module
   * Get all users logs
   */
  private GET_USERS_LOGS_FROM_BACKEND!: Function;

  /**
   *  Local data
   */
  filterOptions: Array<object> = [
    { text: "UserName", value: "userName" },
    { text: "Project", value: "project" },
    { text: "Date", value: "date" },
    { text: "Grape", value: "grape" }
  ];

  logsOptionSelected: Array<object> = [];
  searchLocalQuery: string = "";
  getUsersLogs: null | ActionMessage = null;

  /**
  +Pagination
  */
  perPageOptions: Array<number> = [100, 200, 300, 500];
  currentPage: number = 1;
  perPage: number = 100;
  loading = false;
  filterIsOpen = false;
  created() {
    this.loading = true;
    this.GET_USERS_LOGS_FROM_BACKEND({
      pageToGo: 0,
      perPage: this.perPage
    }).then(() => {
      this.loading = false;
    });
  }

  get filteredLogs() {
    const filtered = this.GET_FORMATED_DATA.filter((item: any) => {
      let stringItem: string = JSON.stringify(item).toLowerCase();
      return stringItem.includes(this.searchLocalQuery.toLowerCase());
    });
    return filtered.length > 0 ? filtered : [];
  }
  async handlePerPageChange() {
    this.getUsersLogs = null;
    try {
      let getMoreLog = await this.GET_USERS_LOGS_FROM_BACKEND({
        pageToGo: 0,
        perPage: this.perPage
      });
      if (getMoreLog.status === 202) {
        console.log("Getting more logs");
      }
    } catch (error) {
      this.getUsersLogs = {
        class: "danger",
        msg: error as TranslateResult
      };
    }
  }

  async HandlePageChange(pageToGo: number = 0) {
    try {
      let getMoreLog = await this.GET_USERS_LOGS_FROM_BACKEND({
        pageToGo: pageToGo - 1,
        perPage: this.perPage
      });
      if (getMoreLog.status === 202) {
        this.currentPage = pageToGo;
      }
    } catch (error) {
      this.getUsersLogs = {
        class: "danger",
        msg: error as string
      };
    }
  }
  handleResetFiltering() {
    this.perPage = 100;
    this.searchLocalQuery = "";
    this.GET_USERS_LOGS_FROM_BACKEND();
  }
  handleFilterOpen() {
    this.filterIsOpen = !this.filterIsOpen;
  }
}
