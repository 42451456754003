
/**
 * Project overview All the stats for the project
 * and a link to the projects that have status alert or warning
 *
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update to typescript.
 */
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({
  name: "CustomPagination"
})
export default class CustomPagination extends Vue {
  @Prop({ type: Number, default: 1 }) totalPages!: number;
  @Prop({ type: Number, default: 1 }) currentPage!: number;
  @Prop({ type: Number, default: 3 }) maxVisiblePages!: number;

  private get startPage() {
    if (this.currentPage === 0 || this.currentPage === 1) {
      return 1;
    }
    if (this.currentPage === this.totalPages) {
      return this.totalPages - this.maxVisiblePages + 1;
    }
    return this.currentPage;
  }
  private get endPage() {
    return Math.min(this.startPage + this.maxVisiblePages - 1, this.totalPages);
  }

  private get pages() {
    const range = [];
    console.log(this.startPage);
    for (let i = this.startPage; i <= this.endPage; i += 1) {
      range.push(i);
    }
    return range;
  }

  handleLoadMore(item: number) {
    console.log(item);
    this.$emit("loadMore", item);
  }
}
